<template>
  <v-card elevation="1" outlined :class="'mb-2 ' + cardRounded" color="grey lighten-4" v-if="(typeof data != 'undefined')">
    <v-card-title class="d-flex py-1" style="line-height: normal;">
      <v-avatar>
        <v-img :src="resourceUrl(data.user_img)"></v-img>
      </v-avatar>
      <div class="px-2">
        <h6 style="font-size: 14px;"><app-user-info :username="data.username">{{ data.username }}</app-user-info></h6>
        <small class="d-block text--disabled" style="font-size: 12px;">{{ data.employee_position }}</small>
        <small class="d-block text--disabled" style="font-size: 12px;">{{ data.comment_datetime }}</small>
      </div>
    </v-card-title>
    <v-card-text class="py-0" style="max-height: 100px;overflow-y: hidden;">
      <pre class="ipre" style="white-space: pre-wrap;">{{ data.comment }}</pre>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <CommentDetail :data="data"></CommentDetail>
    </v-card-actions>
  </v-card>
</template>

<script>
import CommentDetail from './CommentDetail.vue';
export default {
  name: 'comment-detail',
  components: { CommentDetail },
  props: ["data"],
  computed: {
    cardRounded() {
      if (this.data.username == this.$store.state.loginInfo.username) {
        return 'rounded-b-xl rounded-tl-xl'
      } else {
        return 'rounded-b-xl rounded-tr-xl'
      }
    },
    myComment() {
      if (this.data.username == this.$store.state.loginInfo.username) {
        return true
      } else {
        return false
      }
    }
  }

}
</script>

<style scoped>
.ipre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
</style>