var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"elevation":_vm.elevation}},[_c('v-toolbar',{attrs:{"elevation":"0","dense":""}},[_c('v-toolbar-title',[_vm._v("Comments")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","icon":""},on:{"click":function($event){_vm.dialog=true}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-list-box")])]}}])},[_c('span',[_vm._v("Show All")])])],1),_c('v-btn',{attrs:{"text":"","small":"","icon":""},on:{"click":function($event){return _vm.getComments(true)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('AddComment',{attrs:{"berita_acara_header_id":_vm.berita_acara_header_id},on:{"callback":function($event){return _vm.getComments(true)}}})],1),_c('v-divider'),((_vm.loading == true))?_c('div',{staticClass:"mb-2 pa-2 d-block text-center"},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"color":"primary","indeterminate":""}})],1):_vm._e(),((_vm.comments.length == 0) && (_vm.loading == false))?_c('div',{staticClass:"mb-2 pa-2"},[_c('v-alert',{attrs:{"type":"info"}},[_vm._v("Comment not found!")])],1):_vm._e(),(_vm.comments.length > 0)?_c('div',{staticClass:"mb-2 pa-2"},[_vm._l((_vm.comments_limit),function(item,i){return _c('div',{key:i},[_c('CommentCard',{attrs:{"data":item}})],1)}),(_vm.comments.length > _vm.limit)?_c('v-btn',{attrs:{"color":"red lighten-2","text":"","block":"","small":""}},[_vm._v(" Show All ")]):_vm._e(),_c('v-dialog',{attrs:{"width":"600","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_c('span',[_vm._v("All Comment")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","icon":""},on:{"click":function($event){return _vm.getComments(true)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}],null,false,3840759951)},[_c('span',[_vm._v("Refresh")])])],1),_c('AddComment',{attrs:{"berita_acara_header_id":_vm.berita_acara_header_id},on:{"callback":function($event){return _vm.getComments(true)}}}),_c('v-btn',{attrs:{"text":"","small":"","icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"py-2"},_vm._l((_vm.comments),function(item,i){return _c('div',{key:i},[_c('CommentCard',{attrs:{"data":item}})],1)}),0),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }